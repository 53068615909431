import React, { useEffect, useState } from "react";
import useThemeStore from "../../store/themeStore";
import { ThemeProps } from "../../utils/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import propertyManagement from "../../images/vesta/mini-cityscape.svg";
import propertyRefurbishment from "../../images/vesta/mini-cityscape.svg";
import mortgageBroker from "../../images/vesta/MortgageCard.svg";
import sellProperty from "../../images/vesta/SellProperty.svg";
import stampDuty from "../../images/vesta/StampDuty.svg";
import dealSourcing from "../../images/vesta/mini-cityscape.svg";
import conveyancing from "../../images/vesta/Conveyancing.png";

interface Props {
  setIsModalOpen: (value: boolean) => void;
  selectedProductIdSet: (value: string) => void;
  numberOfVisibleCards: number; // Prop to control number of visible cards
}

export interface CustomCardProps {
  title: string;
  description: string;
  logo: string;
  bulletPoints: string[];
  onOpenModal: (productId: string) => void;
  productId: string;
  isFirst: boolean; // Indicates whether it's the first card
  isLast: boolean; // Indicates whether it's the last card
}

export const ProductIds = {
  productManagement: "661f66e260e8287c128c29fe",
  mortgageBroker: "661f66e360e8287c128c2a20",
  conveyancing: "661f66e460e8287c128c2a28",
  propertyRefurbishment: "661f66e460e8287c128c2a2a",
  sellAProperty: "661f66e460e8287c128c2a2c",
  dealSourcing: "661f66e460e8287c128c2a2e",
  stampDutyReclaim: "6620cfe2e2142a237355affd",
  startCompany: "66dab0dd461d55b5cdbad8a9",
};

export const CUSTOM_CARDS = [
  {
    title: "Property Management",
    description: "This is card 1",
    logo: propertyManagement,
    bulletPoints: ["10% Management Fee", "Nationwide Coverage", "Tech-Driven Property Management", "Great Service For a Low Cost"],
    productId: ProductIds.productManagement,
  },
  {
    title: "Mortgage Broker",
    description: "This is card 2",
    logo: mortgageBroker,
    bulletPoints: ["Whole Market Broker", "Responsive & Easy To Talk To", "Can Help on Small, Medium & Large Properties", "Quality at Affordable Rates"],
    productId: ProductIds.mortgageBroker,
  },
  {
    title: "Conveyancing",
    description: "This is card 3",
    logo: conveyancing,
    bulletPoints: ["Panel of Solicitors Available", "Dual Representation with Most Lenders", "Proactive & Solution Focused", "Low Cost Fees"],
    productId: ProductIds.conveyancing,
  },
  {
    title: "Property Refurbishment",
    description: "This is card 4",
    logo: propertyRefurbishment,
    bulletPoints: ["Nationwide Coverage", "Very Fairly Priced", "Project Management Included", "Specialise in Resolving Abandoned Projects"],
    productId: ProductIds.propertyRefurbishment,
  },
  {
    title: "Sell a Property",
    description: "This is card 5",
    logo: sellProperty,
    bulletPoints: ["Commision Based Revenue", "Off Market Property Specialists", "Worldwide Exposure", "Access to Active Buyers Looking For Assets"],
    productId: ProductIds.sellAProperty,
  },
  {
    title: "Deal Sourcing",
    description: "This is card 6",
    logo: dealSourcing,
    bulletPoints: ["£1500 Minimum Per Property", "Custom Branded Portal", "£300m Worth Of Properties", "Training to Optimize Earning Potential"],
    productId: ProductIds.dealSourcing,
  },
  {
    title: "Stamp Duty Reclaim",
    description: "This is card 7",
    logo: stampDuty,
    bulletPoints: ["Reclaim Stamp Duty", "Any Asset Purchased (Up To 4 Years)", "Claim For Uninhabitable Property", "100% Success Rate"],
    productId: ProductIds.stampDutyReclaim,
  },
  {
    title: "Start a Company",
    description: "This is card 8",
    logo: stampDuty,
    bulletPoints: ["Company Setup Within a Few Days", "Annual Tax Returns", "Tech Enabled Tax Savings", "Only £21.98 Per Month"],
    productId: ProductIds.startCompany,
  },
];

export const CustomCard = ({ title, logo, bulletPoints, onOpenModal, productId }: CustomCardProps) => {
  const themes = useThemeStore((state) => state.theme as unknown as ThemeProps);
  const textColor = themes.txtColor === "rgba(255,255,255,1)" ? themes.txtColor : "#FFFFFF";
// Utility function to check for white or near-white shades
const getBackgroundAndTextColor = (color: string, fallbackColor: string, defaultTextColor: string) => {
  if (isWhiteOrShade(color)) {
    return {
      backgroundColor: fallbackColor,
      textColor: 'white', // Set the text color to white if the background is near white
    };
  } else {
    return {
      backgroundColor: color,
      textColor: defaultTextColor, // Keep the original text color
    };
  }
};

const isWhiteOrShade = (color: string) => {
  if (color.toLowerCase() === "white" || color === "rgba(255,255,255,1)") return true;
  if (/^#fff[a-f0-9]{0,3}$/i.test(color)) return true;

  const rgba = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([\d\.]+)?\)/);
  if (rgba) {
    const [_, r, g, b] = rgba.map(Number);
    return r >= 240 && g >= 240 && b >= 240;
  }

  return false;
};

  return (
    <div className="shadow-md rounded-xl overflow-hidden h-full flex flex-col bg-transparent">
      <div 
        style={{
          background: themes.color2,
          color: textColor,
        }}
        className="flex justify-between items-center p-2 rounded-t-xl"
      >
        <span className="font-bold">
          <FontAwesomeIcon icon={faStar} className="mr-1 text-sm" /> Featured Product
        </span>
      </div>

      <div className="flex-grow flex flex-col justify-between">
        <div>
          <img 
            src={logo} 
            alt={title} 
            className="w-full object-cover rounded-t" 
          />
          <div className="mt-10 flex justify-center">
            <div className="flex flex-col justify-between p-4 border rounded-xl border-black shadow-lg" style={{ width: "85%", backgroundColor: "white" }}>
              <h2 className="pt-4 text-xl font-bold mb-3 text-center">{title}</h2>
              <ul className="pt-4 list-disc list-inside space-y-2">
                {bulletPoints.map((point, index) => (
                  <li key={index} className="text-sm">{point}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <button
        onClick={() => onOpenModal(productId)}
        style={{
          backgroundColor: themes.color2,
          color: textColor,
        }}
        className="w-full text-center py-2 px-4 transition duration-300 ease-in-out"
        onMouseOver={(e) => {
          e.currentTarget.style.backgroundColor = isWhiteOrShade(themes?.color1) ? 'black' : themes?.color1 || "alternateHoverColor";
        }}
        onMouseOut={(e) => {
          e.currentTarget.style.backgroundColor = themes?.color2 || "defaultColor";
        }}
      >
        Enquire Here
      </button>

    </div>
  );
};

/* export const FeaturedCardsCarousel: React.FC<Props> = (props) => {
  const { setIsModalOpen, selectedProductIdSet, numberOfVisibleCards } = props;

  const themes = useThemeStore((state) => state.theme as unknown as ThemeProps);

  // Filter cards based on hide conditions
  const visibleCards = CUSTOM_CARDS.filter((card) => {
    if (card.productId === ProductIds.dealSourcing && themes?.hideDealSourcing) return false;
    if (card.productId === ProductIds.conveyancing && themes?.hideConveyancing) return false;
    if (card.productId === ProductIds.mortgageBroker && themes?.hideMortgageBroker) return false;
    if (card.productId === ProductIds.productManagement && themes?.hidePropertyManagement) return false;
    if (card.productId === ProductIds.propertyRefurbishment && themes?.hidePropertyRefurbishment) return false;
    if (card.productId === ProductIds.sellAProperty && themes?.hideSellProperty) return false;
    if (card.productId === ProductIds.stampDutyReclaim && themes?.hideStampDutyReclaim) return false;
    if (card.productId === ProductIds.startCompany && themes?.hideStartCompany) return false;
    return true;
  });

  const [visibleCardsSet, setVisibleCardsSet] = useState(() => {
    const randomStartIndex = Math.floor(Math.random() * visibleCards.length);
    return Array.from({ length: numberOfVisibleCards }, (_, i) => {
      return visibleCards[(randomStartIndex + i) % visibleCards.length];
    });
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleCardsSet((prevVisibleCards) => {
        const lastVisibleIndex = visibleCards.indexOf(prevVisibleCards[numberOfVisibleCards - 1]);
        const nextStartIndex = (lastVisibleIndex + 1) % visibleCards.length;
        const newVisibleCards = [];
        for (let i = 0; i < numberOfVisibleCards; i++) {
          const index = (nextStartIndex + i) % visibleCards.length;
          newVisibleCards.push(visibleCards[index]);
        }
        return newVisibleCards;
      });
    }, 15000); // 15 seconds interval

    return () => clearInterval(interval);
  }, [visibleCards, numberOfVisibleCards]);

  return (
    <div style={{
      backgroundImage: `url(${backgroundPattern})`,
      backgroundRepeat: 'repeat',
      backgroundSize: '50px 50px',
      backgroundColor: '#FFF',
      padding: '20px',
      minHeight: '400px',
      position: 'relative',
      zIndex: 0,
    }}>
      <div className="relative z-10 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 w-full m-auto pt-4 mb-1">
        {visibleCardsSet.map((card, index) => (
          <div key={index} className="h-full">
            <CustomCard isFirst={false} isLast={false} {...card} onOpenModal={(productId) => {
              selectedProductIdSet(productId);
              setIsModalOpen(true);
            }} />
          </div>
        ))}
      </div>
    </div>
  );
}; */

export default CustomCard;
