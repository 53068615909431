import { useEffect, useState } from "react";
import useThemeStore from "../../store/themeStore";
import { ThemeProps } from "../../utils/theme";
import { sendEnquiry } from "../../utils/properties";
import useTokenStore from "../../store/store";
import jwt2json from "../../utils/jwt2json";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  productId: string;
  user?: {
    name: string;
    phoneNumber: string;
    email: string;
  };
}

const EMPTY = "";

let nastyFlag = false;

export default function FeatureEnquiryModal(props: Props) {
  const { isOpen, onClose, productId } = props;
  const jwt = useTokenStore((state) => state.token);
  const user = jwt ? jwt2json(jwt) : null;
  const { _id: userId } = user || {};
  const [busy, busySet] = useState<boolean>(false);
  const [name, nameSet] = useState<string>(user?.name || EMPTY);
  const [phoneNumber, phoneNumberSet] = useState<string>(user?.phoneNumber || EMPTY);
  const [email, emailSet] = useState<string>(user?.email || EMPTY);
  const themes = useThemeStore((state) => state.theme as unknown as ThemeProps);
  
  const [statusMessage, setStatusMessage] = useState<string>("");
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);

  let messageTimer: NodeJS.Timeout;
  let redirectTimer: NodeJS.Timeout;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement> | null = null) => {
    event?.preventDefault();
    busySet(true);
    
    setStatusMessage("Sending message...");
    setShowStatusModal(true);

    const data = { name, phoneNumber, email, productId, userId };

    try {
      const result = await sendEnquiry(data);

      if (result?.status === 200 || result?.message?.toUpperCase() === "OK") {
        messageTimer = setTimeout(() => {
          setStatusMessage("Thank you for your interest, the team will be in touch shortly...");
        }, 3000);

        redirectTimer = setTimeout(() => {
          setShowStatusModal(false);
          if (user === null) {
            window.location.href = "/register";
          } else {
            onClose();
          }
        }, 6000);
      } else {
        alert("An unexpected error occurred. Please try again.");
        setShowStatusModal(false);
      }
    } catch (error) {
      console.error("Error when submitting form", error);
      alert("An error occurred while submitting your enquiry. Please try again later.");
      setShowStatusModal(false);
    } finally {
      busySet(false);
      nastyFlag = false;
    }
  };

  useEffect(() => {
    if (isOpen && user?.email && !busy && !nastyFlag) {
      nastyFlag = true;
      handleSubmit();
    }

    return () => {
      clearTimeout(messageTimer);
      clearTimeout(redirectTimer);
    };
  }, [isOpen]);

  const updateField = (event: any, setFn: any) => setFn(event.target.value || "");

  const inputStyle = {
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid black",
    boxSizing: "border-box" as "border-box",
  };

  const renderStatusModal = () => (
    <div className="modal-backdrop" style={{
      position: "fixed", top: 0, left: 0, right: 0, bottom: 0,
      backgroundColor: "rgba(0,0,0,0.5)", display: "flex",
      alignItems: "center", justifyContent: "center", zIndex: 1000,
    }}>
      <div className="modal-content" style={{
        background: "white", padding: "20px", borderRadius: "5px",
        width: "400px", maxWidth: "90%", textAlign: "center",
      }}>
        <p>{statusMessage}</p>
      </div>
    </div>
  );

  // Utility function to check for white or near-white shades
  const getBackgroundAndTextColor = (color: string, fallbackColor: string, defaultTextColor: string) => {
    if (isWhiteOrShade(color)) {
      return {
        backgroundColor: fallbackColor,
        textColor: 'white', // Set the text color to white if the background is near white
      };
    } else {
      return {
        backgroundColor: color,
        textColor: defaultTextColor, // Keep the original text color
      };
    }
  };
  
  const isWhiteOrShade = (color: string) => {
    if (color.toLowerCase() === "white" || color === "rgba(255,255,255,1)") return true;
    if (/^#fff[a-f0-9]{0,3}$/i.test(color)) return true;
  
    const rgba = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([\d\.]+)?\)/);
    if (rgba) {
      const [_, r, g, b] = rgba.map(Number);
      return r >= 240 && g >= 240 && b >= 240;
    }
  
    return false;
  };

  return (
    <>
      {isOpen && !user && (
        <div className="modal-backdrop" style={{
          position: "fixed", top: 0, left: 0, right: 0, bottom: 0,
          backgroundColor: "rgba(0,0,0,0.5)", display: "flex",
          alignItems: "center", justifyContent: "center", zIndex: 999,
        }}>
          <div className="modal-content" style={{
            position: "relative", background: "white", padding: "20px",
            borderRadius: "5px", width: "400px", maxWidth: "90%",
            boxSizing: "border-box", display: "flex", flexDirection: "column",
          }}>
            <span onClick={onClose} style={{
              position: "absolute", top: "10px", right: "10px",
              cursor: "pointer", fontSize: "20px", fontWeight: "bold",
              transition: "transform 0.2s", display: "inline-block",
            }} onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.3)"}
               onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"}>
              &times;
            </span>
            <p style={{ marginBottom: "10px" }}>For More Details Contact Us</p>
            <form onSubmit={handleSubmit} style={{
              display: "flex", flexDirection: "column", width: "100%",
            }}>
              <fieldset disabled={busy} style={{
                display: "flex", flexDirection: "column", width: "100%",
                border: "none", padding: 0, margin: 0,
              }}>
                <div style={{
                  display: "flex", justifyContent: "space-between", marginBottom: "10px",
                }}>
                  <input type="text" name="name" value={name} onChange={(event) => updateField(event, nameSet)}
                         placeholder="Name*" required style={{ ...inputStyle, width: "48%" }} />
                  <input type="tel" name="phoneNumber" value={phoneNumber} onChange={(event) => updateField(event, phoneNumberSet)}
                         placeholder="Phone*" pattern="[0]{1}[0-9]{10}" title="11 Digits eg 07851234567" required
                         style={{ ...inputStyle, width: "48%" }} />
                </div>
                <div style={{
                  display: "flex", justifyContent: "space-between", marginBottom: "10px",
                }}>
                  <input type="email" name="email" value={email} onChange={(event) => updateField(event, emailSet)}
                         placeholder="Email*" required style={{ ...inputStyle, width: "65%" }} />
                  <button type="submit" style={{
                    width: "30%", 
                    background: themes.color2, // Test Background color
                    color: isWhiteOrShade(themes?.color1) ? 'white' : themes?.txtColor, // Text color logic
                    padding: "10px", 
                    borderRadius: "5px", 
                    border: "none", 
                    cursor: "pointer", 
                    transition: "background 0.3s ease", 
                    boxSizing: "border-box",
                  }} 
                  onMouseOver={(e) => e.currentTarget.style.background = isWhiteOrShade(themes?.color1) ? 'black' : themes?.color1}
                  onMouseOut={(e) => e.currentTarget.style.background = themes.color2}>
                    Submit
                  </button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      )}
      {showStatusModal && renderStatusModal()}
    </>
  );
}