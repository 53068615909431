import { useState, useEffect } from 'react';

/**
 * Custom hook to determine if the current device is mobile based on the viewport width.
 * @param breakpoint - The width (in pixels) below which the device is considered mobile. Default is 768px.
 * @returns A boolean indicating whether the device is mobile.
 */
const useIsMobile = (breakpoint: number = 768): boolean => {
  const isClient = typeof window === 'object';

  const getIsMobile = () => (isClient ? window.innerWidth < breakpoint : false);

  const [isMobile, setIsMobile] = useState<boolean>(getIsMobile());

  useEffect(() => {
    if (!isClient) {
      console.log('useIsMobile: Not running on the client side.');
      return;
    }

    const handleResize = () => {
      const currentIsMobile = getIsMobile();
      setIsMobile(currentIsMobile);
      console.log(`useIsMobile: Viewport resized. isMobile: ${currentIsMobile}`);
    };

    window.addEventListener('resize', handleResize);
    console.log(`useIsMobile: Initialized. isMobile: ${isMobile}`);

    // Initial check
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [breakpoint, isClient, isMobile]);

  return isMobile;
};

export default useIsMobile;
