import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useTokenStore from "../store/store";
import useThemeStore from "../store/themeStore";
import { ThemeProps } from "../utils/theme";
import { ResultMessageProps, verifyOTP } from "../utils/user";
import backgroundPattern from "../images/vesta/vesta-property-pattern-01-teal-bg.svg";
import Footer from "../components/headerFooter/Footer";

type Inputs = {
  email: string;
  otp: Number;
};

function VerifyOtp() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<ResultMessageProps>({
    message: "",
    status: 0,
  });
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  useEffect(() => {
    setEmail(localStorage.getItem("email"));
  }, []);

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    setLoading(true);
    data.email = email!;
    const response = await verifyOTP(data);
    setMessage(response);
    setLoading(false);
    setTimeout(() => {
      setMessage({ message: "", status: 0 });
    }, 5000);

    if (response.status === 200) {
      navigate("/login");
    }
  };

  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );
  const getToken = useTokenStore((state) => state.token);

  useEffect(() => {
    if (getToken) {
      navigate("/listing");
    }
  }, [getToken, navigate]);

  useEffect(() => {
    const setBodyStyles = () => {
      document.body.style.display = "flex";
      document.body.style.flexDirection = "column";
      document.body.style.minHeight = "100vh";
      document.body.style.margin = "0";
      document.body.style.position = "relative";
      document.body.style.overflow = "auto";
    };

    setBodyStyles();

    return () => {
      document.body.style.display = "";
      document.body.style.flexDirection = "";
      document.body.style.minHeight = "";
      document.body.style.margin = "";
      document.body.style.position = "";
      document.body.style.overflow = "";
    };
  }, []);

  const isMobile = window.innerWidth <= 768;

  const isWhiteOrShade = (color: string) => {
    if (color.toLowerCase() === "white" || color === "rgba(255,255,255,1)") return true;
    if (/^#fff[a-f0-9]{0,3}$/i.test(color)) return true;
    const rgba = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([\d\.]+)?\)/);
    if (rgba) {
      const [_, r, g, b] = rgba.map(Number);
      return r >= 240 && g >= 240 && b >= 240;
    }
    return false;
  };

  return (
    <div
      className="VerifyOtp"
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        position: "relative",
        overflow: "auto",
      }}
    >
      <Helmet>
        <title>Verify OTP Code | {getThemes && getThemes.name}</title>
      </Helmet>

      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${backgroundPattern})`,
          backgroundRepeat: "repeat",
          backgroundSize: "60px 60px",
          zIndex: -1,
        }}
      />

      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: isMobile ? "160px 20px 80px" : "100px 20px 80px",
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-white flex flex-wrap justify-center pt-8 pb-8 px-4"
          style={{
            width: "85%",
            maxWidth: "500px",
            border: "1px solid #ddd",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            zIndex: 1,
            backgroundColor: "white",
            marginBottom: "20px",
          }}
        >
          <div className="w-full">
            <p className="text-3xl font-roboto font-medium pb-8 pt-8 text-center">
              Verify OTP Code
            </p>

            <div className="w-full py-2">
              <label
                htmlFor="input-label-with-helper-text"
                className="block text-base font-medium mb-2 text-left"
                style={{
                  color: "black",
                  fontSize: "1rem",
                  fontWeight: "500",
                  paddingLeft: "8px",
                }}
              >
                OTP Code
              </label>
              <input
                type="number"
                id="input-label-with-helper-text"
                className="py-3 px-4 block w-full rounded-md text-sm"
                style={{
                  border: "1px solid black",
                  borderRadius: "0.375rem",
                  padding: "0.75rem",
                  color: "#2D3748",
                  backgroundColor: "#FFF",
                }}
                placeholder="OTP Code"
                aria-describedby="hs-input-helper-text"
                {...register("otp", { required: true })}
              />
              <small className="flex justify-start pt-3 text-red-hover-ce312b">
                {errors.otp && <span>OTP is required</span>}
              </small>
            </div>

            <div className="text-center">
              <button
                type="submit"
                style={{
                  width: "30%",
                  background: isWhiteOrShade(getThemes?.color2) ? "black" : getThemes.color2,
                  color: isWhiteOrShade(getThemes.color1) ? "white" : getThemes.txtColor,
                  padding: "10px",
                  borderRadius: "5px",
                  border: "none",
                  cursor: "pointer",
                  transition: "background 0.3s ease",
                  boxSizing: "border-box",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.background = isWhiteOrShade(getThemes?.color1) ? "black" : getThemes.color1;
                  e.currentTarget.style.color = isWhiteOrShade(getThemes?.color1) ? "white" : getThemes.txtColor;
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.background = isWhiteOrShade(getThemes?.color2) ? "black" : getThemes.color2;
                  e.currentTarget.style.color = isWhiteOrShade(getThemes.color1) ? "white" : getThemes.txtColor;
                }}
              >
                Verify Now
                {loading && (
                  <span
                    className="animate-spin border-btnscolor inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
                    role="status"
                    aria-label="loading"
                    style={{ marginLeft: "10px" }}
                  />
                )}
              </button>
            </div>
          </div>
        </form>
      </div>

      <Footer />

      {/* Toast Notification */}
      {message.status !== 0 && (
        <div
          className={`fixed bottom-4 left-1/2 transform -translate-x-1/2 ${
            message.status === 200 ? "bg-grey-848484" : "bg-red-be1e2e"
          } text-white p-4 rounded-md shadow-lg`}
          style={{ zIndex: 1000 }}
        >
          <p>{message.message}</p>
          <button
            onClick={() => setMessage({ ...message, status: 0 })}
            className="mt-2 px-4 py-2 bg-white text-red-be1e2e rounded"
          >
            OK
          </button>
        </div>
      )}
    </div>
  );
}

export default VerifyOtp;